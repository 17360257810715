<template>
  <div>
    <template v-if="isStatesLoading">
      <v-row class="justify-center ma-0 pa-0">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
    </template>
    <template v-else>
      <AutomationWarnings v-show="automationInfo.name != AUTOMATION.MARKETING_AUTOMATION && automationInfo.name != AUTOMATION.EVENT_TASK" :template="'corporate'"></AutomationWarnings>
      <div class="mr-6 mt-4">
        <AutomationHeader v-if="automationInfo.name || automationInfo.corporate_description" :automation-info="automationInfo" :template="'corporate'"></AutomationHeader>
        <EventTaskCorporateTable v-if="automationInfo.name === AUTOMATION.EVENT_TASK && TaskTypeConfigurations.length" />
        <hb-empty-state v-else-if="automationInfo.name === AUTOMATION.EVENT_TASK && TaskTypeConfigurations.length == 0" :message="`No Event Task automation is available for that company.`" :showBtn="false" ></hb-empty-state>
        <AutomationAccordian v-if="workflows.length && automationInfo.name !== AUTOMATION.EVENT_TASK":workflows="workflows" :selectedAutomationType="automationInfo" :template="'corporate'"></AutomationAccordian>
        <hb-empty-state v-else-if="workflows.length === 0 && automationInfo.name !== AUTOMATION.EVENT_TASK" :message="`No delinquency automation is available for that company.`" :showBtn="false"></hb-empty-state>
      </div>
    </template>
  </div>
</template>

<script type="text/babel">
import { mapGetters, mapActions, mapMutations } from "vuex";
import AutomationAccordian from "./AutomationAccordian.vue";
import AUTOMATION from '@/constants/automation.js';
import AutomationHeader from "./AutomationHeader.vue";
import AutomationWarnings from "./AutomationWarnings";
import EventTaskCorporateTable from "./EventTaskCorporateTable.vue";

export default {
  name: "CorporateTemplate",
  data() {
    return {
      setupModal: [],
      panel: true,
      isStatesLoading: true,
    };
  },
  props: {
    automationInfo: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    AutomationAccordian,
    AutomationHeader,
    AutomationWarnings,
    EventTaskCorporateTable
  },
  computed: {
    ...mapGetters({
      workflows: "automationStore/getAllWorkflows",
      TaskTypeConfigurations: 'taskCenterStore/getTaskTypeConfigurations'
    }),
  },
  async created() {
    this.AUTOMATION = AUTOMATION;
    await this.loadAutomations();
    this.loadMissingActions();
    this.setSelectedAutomationType(this.automationInfo);
  },
  methods: {
    ...mapActions({
      fetchAllWorkflows: 'automationStore/fetchAllWorkflows',
      fetchAllMissingActions: 'automationStore/fetchAllMissingActions'
    }),
    ...mapMutations({
      setSelectedAutomationType: 'automationStore/setSelectedAutomationType'
    }),
    async loadAutomations() {
      if (this.automationInfo && this.automationInfo?.id) {
        await this.fetchAllWorkflows({
          automation_type_id: this.automationInfo.id
        });
        this.isStatesLoading = false;
      }
    },
    loadMissingActions() {
      if (this.automationInfo && this.automationInfo?.id) {
        this.fetchAllMissingActions({ automation_type_id: this.automationInfo.id, property_id: '' });
      }
    }
  },
  watch: {
    async automationInfo(info) {
      this.setSelectedAutomationType(info);
      this.isStatesLoading = true;
      if (info && info?.id) {
        await this.loadAutomations();
        this.loadMissingActions();
      }
    }
  }

};
</script>

<style lang="scss" scoped></style>

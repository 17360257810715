<template>
  <div>
    <hb-data-table
      :headers="headers"
      :items="taskTypeConfigurations"
      @click:row="onRowClick"
    >
      <template v-slot:item.hot_task="{ item }">
        <v-icon>{{ item.hot_task ? 'mdi-check' : 'mdi-close' }}</v-icon>
      </template>
      <template v-slot:item.last_updated="{ item }">
        {{ formatDate(item.last_updated) }}
      </template>
      <template v-slot:item.assign_to_users="{ item }">
        <hb-tooltip>
          <template v-slot:item>
            <div class="truncate">
              {{ formatAssignedUsers(item.assign_to_users) }}
            </div>
          </template>
          <template v-slot:body>
            <div v-for="user in item.assign_to_users" :key="user.id">
              {{ user.name }}
            </div>
          </template>
        </hb-tooltip>
      </template>
    </hb-data-table>
    <UpdateEventTaskModal
      v-model="isModalVisible"
      :taskDetails="selectedTask"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UpdateEventTaskModal from './UpdateEventTaskModal.vue';

export default {
  name: "EventTaskCorporateTable",
  components: {
    UpdateEventTaskModal
  },
  data() {
    return {
      headers: [
        { text: 'Title', value: 'task_name', width: 100 },
        { text: 'Type', value: 'type', width: 100 },
        { text: 'Assigned To', value: 'assign_to_users', width: 100},
        { text: 'Hot Task', value: 'hot_task', width: 30 },
        { text: 'Last Updated', value: 'last_updated', width: 100 },
        { text: 'Last Updated By', value: 'last_updated_by', width: 100, sortable: false }
      ],
      selectedTask: null,
      isModalVisible: false
    };
  },
  async created () {
  },
  computed: {
    ...mapGetters({
      taskTypeConfigurations: 'taskCenterStore/getTaskTypeConfigurations'
    }),
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-US', options);
    },
    onRowClick(item) {
      this.selectedTask = item;
      this.isModalVisible = true;
    },
    formatAssignedUsers(users) {
      if (!users || !Array.isArray(users)) return '';
      return users.map(user => user.name || '').filter(Boolean).join(', ');
    }
  }
};
</script>

<style scoped>
.v-icon {
  font-size: 18px;
  color: #4CAF50; /* Green for check */
}
.v-icon.mdi-close {
  color: #F44336; /* Red for cross */
}
.hb-data-table th {
  font-weight: bold;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  cursor: pointer;
}
</style>

<template>
  <hb-modal v-model="isVisible" class="custom-modal" title="Update Event Task">
    <template v-slot:content>
      <hb-form label="Assign to" description="Select one or multiple role(s) AND/OR user(s) to filter from.">
        <hb-combobox
          v-model="taskData.assignTo"
          :items="taskAssignees"
          item-text="name"
          item-value="id"
          label="Search roles or users"
          multiple
        />
      </hb-form>

      <hb-form label="Details" description="Provide details about the task.">
        <hb-textarea
          placeholder="Enter Task details"
          v-model="taskData.details"
        />
      </hb-form>

      <hb-form label="Due Date" description="Select due date or mark as hot task.">
        <hb-switch
          label="Hot Task"
          v-model="taskData.hotTask"
        />
        <hb-date-picker
          label="Select Date"
          :disabled="disable_date"
          v-model="taskData.dueDate"
          :clearable="true"
        />
      </hb-form>
    </template>

    <template v-slot:actions>
      <hb-btn color="primary" @click="updateTask">Update Task</hb-btn>
    </template>
  </hb-modal>
</template>

<script>
import api from '../../../assets/api.js'
import { notificationMixin } from '../../../mixins/notificationMixin.js';
import { mapActions } from "vuex";
import HbDatePicker from '../../../components/assets/HummingbirdDatepicker.vue';


export default {
  name: 'UpdateEventTaskModal',
  mixins: [ notificationMixin ],
  components: {
    HbDatePicker
  },
  props: {
    value: Boolean,
    taskDetails: Object
  },
  data() {
    return {
      isVisible: this.value,
      taskData: {
        assignTo: [],
        details: '',
        hotTask: false,
        dueDate: ''
      },
      taskAssignees: [],
      disable_date: false,
    };
  },
  async created() {
    await this.fetchAssignees();
  },
  watch: {
    value(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      this.$emit('input', val);
    },
    'taskData.hotTask'(newVal, oldVal) {
      if(newVal) {
          this.disable_date = true;
      } else {
        this.disable_date = false;
      }
    },
    taskDetails: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal != null && typeof(newVal) != 'undefined') {
          this.taskData = { 
            assignTo: newVal.assign_to_users[0].id != null ? newVal.assign_to_users.map(user => user) : [],
            details: newVal.details || '',
            hotTask: newVal.hot_task === 1,
            dueDate: newVal.last_updated || ''
          };
        }
      }
    },
  },
  methods: {
    ...mapActions({
      fetchTaskTypeConfigurations: 'taskCenterStore/fetchTaskTypeConfigurations', 
    }),
    async updateTask() {
      try {
        const response = await api.put(this, api.TASKS_CENTER + `/task-config/${this.taskDetails.task_type_id}`, {
          details: this.taskData.details,
          hotTask: this.taskData.hotTask,
          assignTo: this.taskData.assignTo,
          dueDate: this.taskData.dueDate
        });
        this.showMessageNotification({type: 'success', description: 'Task Configurations Updated Successfully'});
        this.closeModal();
        await this.fetchTaskTypeConfigurations();
      } catch (error) {
        this.showMessageNotification({ type: 'error', description: 'Error in Updating the Task Configurations' });
        this.closeModal();
      }
    },
    async fetchAssignees(){
        let usersRequest = api.get(this, api.ADMIN);
        let rolesRequest = api.get(this, api.ROLES);
        Promise.all([usersRequest, rolesRequest])
        .then(responses => {
            this.taskAssignees = [...responses[0].admins.map( item => { 
                return {
                    id: item.contact_id,
                    name: item.first + ' ' + item.last,
                    is_admin: true
                }
            }), ...responses[1].roles.map( item => {
                return {
                    id: item.id,
                    name: item.name,
                    is_role: true
                }
            })];
        })
    },
    closeModal() {
      this.isVisible = false;
    }
  }
};
</script>

<style scoped>
.custom-modal {
  width: 600px; /* Adjust width as needed */
  max-width: 90%; /* Ensure responsiveness */
  height: 800px; /* Adjust height as needed */
}
</style>
